<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按角色名搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['10001']"
          color="primary"
          dark
          @click="newItemOpenDialog"
        >
          添加角色
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-if="item.name !== '超级管理员'"
            v-permission="['10002']"
            icon
            @click="itemClick(item)"
          >
            <v-icon>{{ icons.mdiArrowRightCircle }}</v-icon>
          </v-btn>
          <v-btn
            v-if="item.name !== '超级管理员'"
            v-permission="['10004']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="editDialog"
      max-width="850"
      transition="dialog-bottom-transition"
    >
      <v-card
        v-if="editDialogLoading"
        color="primary"
        dark
      >
        <v-card-text>
          加载中......
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-card
        v-else
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          {{ !rowId ? '新增角色' : viewRow ? '查看角色': '编辑角色' }}
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          {{ !rowId ? '创建新的角色并为其分配可用权限' : viewRow ? '查看角色的权限信息': '修改角色的名称及权限信息' }}
        </v-card-text>
        <v-card-text class="mt-5">
          <v-text-field
            v-model="row.name"
            label="角色名"
            :rules="[rules.required, rules.counter]"
            :disabled="viewRow"
            validate-on-blur
            counter
            maxlength="10"
            dense
            outlined
          ></v-text-field>
          <v-divider />
          <v-card-text class="pl-0">
            权限管理
          </v-card-text>
          <v-treeview
            v-model="row.permission_codes"
            selectable
            disabled
            selection-type="independent"
            selected-color="primary"
            item-key="code"
            item-text="label"
            :items="permissionsTree"
            open-all
            class="d-sm-flex flex-wrap justify-space-between mr-3"
            @input="selectPermission"
          ></v-treeview>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            v-if="viewRow"
            v-permission="['10003']"
            color="primary"
            dark
            @click="editClick"
          >
            编辑
          </v-btn>
          <v-btn
            v-else
            color="primary"
            dark
            @click="editDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="editDialog = !editDialog"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getRoles, getPermissions, addRole, updateRole, getRole, deleteRole,
} from '@/api/user'
import {
  mdiMagnify, mdiClose, mdiDelete, mdiArrowRightCircle,
} from '@mdi/js'

export default {
  name: 'Roles',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      editDialog: false,
      deleteDialog: false,
      editDialogLoading: true,
      rowId: null,
      viewRow: false,
      row: {
        name: '',
        permission_codes: [],
      },
      permissions: [],
      permissionsTree: [],
      permissionsIdMap: {},
      loading: true,
      rules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 10) || '最大10个字符',
      },
      headers: [
        { text: '角色名', value: 'name' },
        { text: '创建时间', value: 'created_at' },
        { text: '最后更新时间', value: 'updated_at' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiArrowRightCircle,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getRoles(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    async itemClick(item) {
      this.editDialog = true
      this.editDialogLoading = true
      this.viewRow = true
      await this.getPermissions(true)
      this.rowId = item.id
      const { data } = await getRole(item.id)
      this.row.name = data.name
      this.row.permission_codes = data.permissions
      this.editDialogLoading = false
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteRole(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async editClick() {
      await this.getPermissions()
      this.viewRow = false
    },
    selectPermission(keys) {
      const selected = new Set(keys)
      keys.forEach(k => {
        const permObj = this.permissions[this.permissionsIdMap[k]]
        if (permObj.dependent_codes.length) {
          permObj.dependent_codes.forEach(dk => {
            if (!selected.has(dk) && this.permissionsIdMap[dk] !== undefined) {
              this.row.permission_codes.push(dk)
            }
          })
        }
      })
    },
    async getPermissions(disabled = false) {
      const res = await getPermissions()
      this.permissions = res.data
      this.permissionsIdMap = res.data.reduce((acc, el, i) => {
        acc[el.code] = i
        return acc
      }, {})
      const root = []
      this.permissions.forEach(el => {
        el.disabled = disabled
        if (!el.parent_code) {
          root.push(el)
        } else {
          const parentEl = this.permissions[this.permissionsIdMap[el.parent_code]]
          parentEl.children = [...(parentEl.children || []), el]
        }
      })
      this.permissionsTree = root
    },
    async newItemOpenDialog() {
      this.editDialog = true
      this.viewRow = false
      this.editDialogLoading = true
      this.permissionsTree = []
      this.row = {
        name: '',
        permission_codes: [],
      }
      this.rowId = null
      await this.getPermissions()
      this.editDialogLoading = false
    },
    async editDialogSubmit() {
      if (!this.row.name) {
        this.$toast.error('角色名是必填的')
        return
      }
      if (this.row.name.length > 10) {
        this.$toast.error('最大10个字符')
        return
      }
      if (!this.row.permission_codes.length) {
        this.$toast.error('角色权限不能为空')
        return
      }
      if (this.rowId) {
        await updateRole(this.rowId, this.row)
        this.$toast.success('更新成功')
      } else {
        await addRole(this.row)
        this.$toast.success('创建成功')
      }
      this.getDesserts()
      this.editDialog = false
    },
  },
}
</script>
